import Counter from "./Counter/Counter";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import "./HomeStyle.css";

function Home() {
  return (
    <div
      id="homePage"
      className="mainDiv"
      style={{
        backgroundImage: 'url("./visuals/back2.jpg")',
      }}
    >
      <img
        onClick={() => {
          window.open(
            "https://yerson.s3.eu-central-1.amazonaws.com/kitap.pdf",
            "_blank"
          );
        }}
        id="bookImage"
        src="./visuals/kitapkapak.jpg"
      />
      <Counter />
      <Footer />
    </div>
  );
}

export default Home;
