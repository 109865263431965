import React from "react";
import ActivityImages from "./ActivityImages";

function ActivityItem(props) {
  return (
    <div id="activityItemDiv">
      <div>
        <img src={props.iconSource} />
        <h2>{props.title}</h2>
      </div>
      <div>{props.content}</div>
      <div id="activityImagesDiv">
        <ActivityImages images={props.images} />
      </div>
    </div>
  );
}

export default ActivityItem;
