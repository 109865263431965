import ActivityItem from "./ActivityItem";

export const activities = [
  <ActivityItem
    iconSource="https://img.icons8.com/ios/50/000000/oil-offshore-rig.png"
    title="Su Sondajları"
    content="Su sondajı faaliyetlerimiz iki adet sondaj makinesı ile yürütülmektedir. Bunlar; Dodge kamyon üzerine monteli 300 metre delme kapasiteli su sondaj makinesı ile, 6 çeker volvo üzerine monteli 700 metre delme kapasiteli sondaj makinalarıdır. Su sondajlarının delgi çapları 6 inç ile 17 ½ inç arasında değişmektedir. Gerek havalı gerekse Rotary sistemde delgi yapılabilmektedir. Havalı sondajlar firmamızın kendi malı olan Atlas-Copco XRVS-476 marka, 30 Bar-980 CFM Komprasör ile yürütülmektedir. Firmamızın kuruluşundan bugüne kadar gerek özel söktöre gerekse Devlet kurumlarına muhtelif çap ve derinliklerde çok sayıda su sondaj kuyusu açılmıştır. Firmamız tarafından açılan su sondaj kuyularının bir bölümü “Referanslar” bölümünde verilmektedir."
    images={["./visuals/activities/su1.png", "./visuals/activities/su2.jpg"]}
  />,
  <ActivityItem
    iconSource="https://img.icons8.com/pastel-glyph/64/000000/jackhammer.png"
    title="Zemin Sondajları"
    content="Firmamız sondaj makinaları ile zemin araştırma sondajları, taş ocağı araştırma sondajları , mermer araştırma sondajları, Maden araştırma sondajları yapılmaktadır. Bu amaçla 3 adedi paletli bir adedi kamyon üzerine monteli olmak üzere toplam 4 adet sondaj makinası mevcuttur. Sondaj makinalarının tamamı firmamızın kendi malıdır. Konveksiyonel delgi yöntemleri yanında Wire-Line sistemde delgi işlemi yapılmaktadır. Sondaj makinaları ile 300 metre derinliğe kadar NQ çapta, 400 metre derinliğe kadar ise BQ Çapta delgi yapılabilmektedir. Firmamızın kuruluşundan bugüne kadar gerek özel söktöre gerekse Devlet kurumlarına muhtelif çap ve derinliklerde çok sayıda zemin araştırma sondajı açılmıştır. Firmamız tarafından açılan zemin araştırma sondajlarının bir bölümü “Referanslar” bölümünde verilmektedir."
    images={[
      "./visuals/activities/zemin1.jpg",
      "./visuals/activities/zemin2.jpg",
      "./visuals/activities/zemin3.jpg",
    ]}
  />,
  <ActivityItem
    iconSource="https://img.icons8.com/ios/50/000000/sea-waves.png"
    title="Deniz Sondajları"
    content="Firmamız 2008 yılında satın aldığı Duba ile birlikte Deniz araştırma sondajlarının yapımına başlamıştır. Bu amaçla denizde 60 metre derinliğe ulaşan çok miktarda araştırma sondajı açılmıştır."
    images={[
      "./visuals/activities/deniz1.jpg",
      "./visuals/activities/deniz2.png",
      "./visuals/activities/deniz3.jpg",
      "./visuals/activities/deniz4.png",
    ]}
  />,
  <ActivityItem
    iconSource="https://img.icons8.com/ios/50/000000/robot.png"
    title="Enjeksiyon"
    content="Firmamızın kurucusu ve Şirket müdürü Ahmet ÇATAL, Altınkaya Barajı ile Atatürk Barajında 2.500.000 metre sondaj ve Enjeksiyonun yapım işinin teknik sorumlusu olarak çalıştı. Firma olarak da Çayeli Bakır İşletmeleri Şaft Enjeksiyonları, Başak HES Sondaj ve Enjeksiyonlarını yaptı. Enjeksiyon için firmamızda iki adet yeni Clivio marka Pompa ile Mikser ve Dinlendirici mevcuttur."
    images={[
      "./visuals/activities/enjek1.jpg",
      "./visuals/activities/enjek2.jpg",
      "./visuals/activities/enjek3.jpg",
    ]}
  />,
  <ActivityItem
    iconSource="https://img.icons8.com/dotty/80/000000/lab-items.png"
    title="Laboratuvar"
    content="1999 Yılında Samsun ilinde Zemin makaniği deneyleri yapmak için kurduğumuz Yerson Zemin-Kaya Mekaniği Laboratuvarı'nı 2011 yılında Beton ve Yapı malzemelerini de ilave ederek YERSON ZEMİN-BETON VE YAPI MALZEMELERİ LABORATUVARI  olarak yeniden oluşturduk. Laboratuvar, firmamızın kendi malı olan toplamda 700 m 2 lik bir alanda faaliyet göstermektedir. Laboratuvarımızda tüm deneyler; ASTM(American Society for Testing Standarts),TSE(Türk Standartları Enstitüsü) ve ISRM (International Society for Rock Mechanics )standartlarına göre yapılmaktadır. Laboratuvarımız Çevre ve Şehircilik Bakanlığı tarafından verilen 24 no 'lu akredite olmuş bir laboratuvardır. Ayrıca Laboratuvarımız ISO 9001-2008 Kalite Yönetim sistemi belgesine sahiptir."
    images={[
      "./visuals/activities/lab1.jpg",
      "./visuals/activities/lab2.jpg",
      "./visuals/activities/lab3.jpg",
    ]}
  />,
  <ActivityItem
    iconSource="https://img.icons8.com/wired/64/000000/globe-earth.png"
    title="Jeofizik Hizmetler"
    content="-Sismik Ölçümler 
-Rezistivite 
-Mikrotrömer 
Ölçümler firmamızın kendine ait cihazlar ile yapılmaktadır."
    images={[
      "./visuals/activities/sismik.jpg",
      "./visuals/activities/resiz.jpg",
      "./visuals/activities/mikrotremor.jpg",
    ]}
  />,
  <ActivityItem
    iconSource="https://img.icons8.com/ios/50/000000/fire-hydrant.png"
    title="Dalgıç Pompa Satış ve Montaj"
    content="Sektöründe öncü çeşitli firmalara ait dalgıç pompaların temini ve montajı firmamızın teknik personeli tarafından yapılmaktadır."
    images={["./visuals/activities/pompa.jpg"]}
  />,
];
