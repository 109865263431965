import React from "react";
import HeaderNavItem from "./HeaderNavItem";

function Header(props) {
  const navItems = [
    <HeaderNavItem href="/" title="Ana Sayfa" />,
    <HeaderNavItem href="/bizkimiz" title="Biz Kimiz" />,
    <HeaderNavItem href="/faaliyet" title="Faaliyet Alanlarımız" />,
    <HeaderNavItem
      href="https://yerson.s3.eu-central-1.amazonaws.com/referans.pdf"
      title="Referanslarımız"
      isBlank={true}
    />,
    <HeaderNavItem href="/galeri" title="Galeri" />,
    <HeaderNavItem href="/iletisim" title="İletişim" />,
    <HeaderNavItem
      href="https://yerson.s3.eu-central-1.amazonaws.com/tan%C4%B1t%C4%B1m.pdf"
      title="Tanıtım Kataloğu"
      isBlank={true}
    />,
  ];
  return (
    <div id="header">
      <img
        onClick={() => {
          window.location.href = "/";
        }}
        src="./visuals/logo/yerson_logo.png"
      />
      <div id="navItems">{navItems}</div>
    </div>
  );
}

export default Header;
