import React from "react";
import { activities } from "./ActivityList";
import "./ActivitiesStyle.css";
function Activities(props) {
  return (
    <div
      id="activitiesDiv"
      className="mainDiv"
      style={{
        backgroundImage: 'url("./visuals/back2.jpg")',
      }}
    >
      {activities}
    </div>
  );
}

export default Activities;
