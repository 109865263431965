import React from "react";
import ContactItem from "./ContactItem";
import "./ContactPageStyle.css";
function ContactPage(props) {
  return (
    <div
      id="contactPageDiv"
      className="mainDiv"
      style={{
        backgroundImage: 'url("./visuals/back2.jpg")',
      }}
    >
      <div id="contactItemsDiv">
        <ContactItem
          iconUrl="./visuals/telephone.png"
          title="Telefon"
          content="0362 432 28 66"
        />
        <ContactItem
          iconUrl="./visuals/office.png"
          title="Ofis"
          content="Yenimahalle Mahallesi,Ali Gaffar Okan Cad. No:95, 55270 Atakum/Samsun"
        />
        <ContactItem
          iconUrl="./visuals/flask.png"
          title="Labobatuvar"
          content="Yenimahalle Mahallesi,Ali Gaffar Okan Cad. No:95, 55270 Atakum/Samsun"
        />
        <ContactItem
          iconUrl="./visuals/mail.png"
          title="Email"
          content="yerson@yerson.com  yersonltd@gmail.com"
        />
      </div>
    </div>
  );
}

export default ContactPage;
