import React from "react";

function ContactItem(props) {
  return (
    <div id="contactItem">
      <img src={props.iconUrl} />
      <div>
        <b>{props.title}</b>
        <p>{props.content}</p>
      </div>
    </div>
  );
}

export default ContactItem;
