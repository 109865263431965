import React, { useEffect, useState } from "react";

const initialTime = new Date("April 21, 1994 08:00:00").getTime();
const secondsInADay = 86400000;
const secondsInAHour = 3600000;

function countUp(setDays, setHours, setMins, setSeconds) {
  const currentTime = new Date();
  const timeDifference = currentTime - initialTime;

  const days = Math.floor((timeDifference / secondsInADay) * 1);
  const hours = Math.floor(
    ((timeDifference % secondsInADay) / secondsInAHour) * 1
  );
  const mins = Math.floor(
    (((timeDifference % secondsInADay) % secondsInAHour) / (60 * 1000)) * 1
  );
  const secs = Math.floor(
    ((((timeDifference % secondsInADay) % secondsInAHour) % (60 * 1000)) /
      1000) *
      1
  );

  setDays(days);
  setHours(hours);
  setMins(mins);
  setSeconds(secs);
}
function Counter(props) {
  const [days, setDays] = useState(null);
  const [hours, setHours] = useState(null);
  const [mins, setMins] = useState(null);
  const [seconds, setSeconds] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      countUp(setDays, setHours, setMins, setSeconds);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div id="counterDiv">
      <h3>1994'den Günümüze</h3>
      <h2>
        {days} Gün {hours} Saat {mins} Dakika {seconds} Saniyedir
      </h2>
      <h3>Sektöründe Lider</h3>
    </div>
  );
}

export default Counter;
