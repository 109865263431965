import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./GalleryPageStyle.css";
import { images } from "./Images";
function GalleryPage() {
  return (
    <div
      id="galleryDiv"
      className="mainDiv"
      style={{
        backgroundImage: 'url("./visuals/back2.jpg")',
      }}
    >
      <Carousel width="45%">{images}</Carousel>
    </div>
  );
}

export default GalleryPage;
