import React from "react";

function HeaderNavItem(props) {
  return (
    <a href={props.href} target={props.isBlank ? "_blank" : undefined}>
      {props.title}
    </a>
  );
}

export default HeaderNavItem;
