import ImageItem from "./ImageItem";
export const images = [
  <ImageItem
    source="./visuals/gallery/1.jpg"
    title="Çarşamba Lisesi Basketbol Takımında ( 1975 )"
  />,
  <ImageItem
    source="./visuals/gallery/2.jpg"
    title="ODTÜ Hazırlık Sınıfı Öğrencileriyle Birlikte ( 1976 )"
  />,
  <ImageItem
    source="./visuals/gallery/3.jpg"
    title="ODTÜ 3.Yurt - Ders Çalışırken ( 1978 )"
  />,
  <ImageItem
    source="./visuals/gallery/4.jpg"
    title="ODTÜ Mineroloji Dersi ( 1978 )"
  />,
  <ImageItem
    source="./visuals/gallery/5.jpg"
    title="ODTÜ Mikropaleontoloji Dersi ( 1980 )"
  />,
  <ImageItem
    source="./visuals/gallery/6.jpg"
    title="ODTÜ Öğrenciler ile Birlikte Ankara - Haymana Teknik Gezisi ( 1981 )"
  />,
  <ImageItem
    source="./visuals/gallery/7.jpg"
    title="ODTÜ Hazırlık Sınıfı ( 1976 )"
  />,
  <ImageItem
    source="./visuals/gallery/8.jpg"
    title="Altınkaya Barajı Şantiye Alanı - DSİ'de Kontrol Mühendisi Olarak Çalışırken ( 1984 )"
  />,
  <ImageItem
    source="./visuals/gallery/9.jpg"
    title="Altınkaya Barajı Şantiye Alanı - DSİ'de Kontrol Mühendisi Olarak Çalışırken ( 1984 )"
  />,
  <ImageItem
    source="./visuals/gallery/10.jpg"
    title="Altınkaya Barajı - DSİ Mühendisleri ( 1984 )"
  />,
  <ImageItem
    source="./visuals/gallery/11.jpg"
    title="Altınkaya Barajı - DSİ Mühendisleri ( 1984 )"
  />,
  <ImageItem
    source="./visuals/gallery/12.jpg"
    title="Atatürk Barajı - Saha Şefi Taner Sökeli ile Birlikte ( 1990 )"
  />,
  <ImageItem
    source="./visuals/gallery/13.jpg"
    title="Atatürk Barajı - Proje Üzerinde Çalışırken ( 1991 )"
  />,
  <ImageItem
    source="./visuals/gallery/14.jpg"
    title="Atatürk Barajı - ATA İnşaat Teknik Mühendis Kadrosu - ATA İnşaat Genel Müdürü Erdoğan Bayındır - DSİ Bölge Müdür Yardımcısı Selami Oğuz ile Birlikte ( 1992 )"
  />,
  <ImageItem
    source="./visuals/gallery/15.jpg"
    title="Atatürk Barajı - DSİ Bölge Müdürü Erdoğan Basmacı ve ATA İnşaat Grup Müdürü Yıldırım Özbayoğlu ( 1992 )"
  />,
  <ImageItem
    source="./visuals/gallery/16.jpg"
    title="Atatürk Barajı - Yabancı Teknik Heyet ile Saha Gezisi ( 1993 )"
  />,
  <ImageItem
    source="./visuals/gallery/17.jpg"
    title="Atatürk Barajı - Genel Görünüm ( 1993 )"
  />,
  <ImageItem
    source="./visuals/gallery/18.jpg"
    title="Atatürk Barajı - Dönemin Cumhurbaşkanı Turgut Özalın Katılımı ile Barajın Açılışı ( 1993 )"
  />,
  <ImageItem
    source="./visuals/gallery/19.jpg"
    title="Atatürk Barajı - Enjeksiyon Tüneli Önü Soletanche Firması Temsilcisi Francouis Decourninge ve Enjeksiyon Şefi Atilla Gürel ile Birlikte ( 1993 )"
  />,
  <ImageItem
    source="./visuals/gallery/20.jpg"
    title="Bayburt Demirözü İlçesi Firmamız Tarafından Açılan Su Kuyusu ( 1995 )"
  />,
  <ImageItem
    source="./visuals/gallery/21.jpg"
    title="Firmamızın Açılış Günü ( 21.04.1994 )"
  />,
  <ImageItem
    source="./visuals/gallery/22.jpg"
    title="Firmamızın Açılış Günü - DSİ Sondaj Şube Müdürü Tunçman Çebi ve DSİ Yeraltı Suları Şube Müdürü Tufan Subası ( 21.04.1994 )"
  />,
  <ImageItem
    source="./visuals/gallery/23.jpg"
    title="Laboratuvar Ekipman - Direk Kesme Makinesi"
  />,
  <ImageItem
    source="./visuals/gallery/25.jpg"
    title="Laboratuvar Ekipman - Likit Limit Deney Malzemeleri"
  />,
  <ImageItem
    source="./visuals/gallery/27.png"
    title="TEKFEN A.Ş. Kirazlık Tesisleri Zemin Etüdü Sondajı"
  />,
  <ImageItem
    source="./visuals/gallery/28.jpg"
    title="Kazık Bütünlük Deneyi Yapılırken"
  />,
  <ImageItem
    source="./visuals/gallery/29.jpg"
    title="Samsun İçme Suyu Kuyuları Büyükşehir Belediye Başkanı ve SASKİ Genel Müdürüne Arazide Bilgi Verilirken"
  />,
  <ImageItem
    source="./visuals/gallery/30.jpg"
    title="Firmamıza ait Paletli Sondaj Makinesi ile Sondaj Yapılırken"
  />,
  <ImageItem
    source="./visuals/gallery/31.jpg"
    title="Firmamıza ait Paletli Sondaj Makinesi ile Sondaj Yapılırken"
  />,
];
