import React from "react";
import ReactDOM from "react-dom";
import Home from "./HomePage/Home";
import { BrowserRouter, Route, Router, Switch } from "react-router-dom";
import Header from "./HomePage/Header/Header";
import ContactPage from "./ContactPage/ContactPage";
import GalleryPage from "./GalleryPage/GalleryPage";
import WhoAreWe from "./WhoAreWe/WhoAreWe";
import Activities from "./Activities/Activities";

ReactDOM.render(
  <BrowserRouter>
    <Header />
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/iletisim" exact component={ContactPage} />
      <Route path="/galeri" exact component={GalleryPage} />
      <Route path="/bizkimiz" exact component={WhoAreWe} />
      <Route path="/faaliyet" exact component={Activities} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
