import React from "react";

function ImageItem(props) {
  return (
    <div>
      <img src={props.source} />
      <p className="legend">{props.title}</p>
    </div>
  );
}

export default ImageItem;
