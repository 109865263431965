import React from "react";
import "./WhoAreWeStyle.css";

function WhoAreWe(props) {
  return (
    <div
      style={{
        backgroundImage: 'url("./visuals/back2.jpg")',
      }}
      className="mainDiv"
      id="whoAreWeDiv"
    >
      <div>
        <b> Öz Geçmiş</b>
        <p>
          Yerson Ltd.Şti 21.04.1994 tarihinde Samsun ilinde Ahmet Çatal ve Nuray
          Çatal tarafından kuruldu. Şirketin kurucu ortağı ve Genel Müdürü Ahmet
          Çatal ,İlk, orta ve Lise eğitimini Çarşamba ilçesinde tamamladı.
          Ortadoğu Teknik Üniversitesi Mühendislik Fakültesi Jeoloji
          Mühendisliği bölümünden 1983 yılında mezun oldu. 1983-1985 yılları
          arasında Devlet Su İşleri Altınkaya Barajında Kontrol Mühendisi olarak
          çalıştı. Askerlik Görevini Milli Savunma Bakanlığında “İngilizce
          Tercüman” olarak yaptı. Askerlik sonrası Atatürk Barajının yapımcı
          şirketi ATA İNŞAAT firmasında Sondaj ve Enjeksiyon grubu teknik işler
          şefi olarak çalıştı. Atatürk Barajında jeoteknik çalışmaları yürüten
          teknik ekibin başında yer aldığı sürede yaklaşık 2,500,000 metre
          sondaj - enjeksiyon ve ankraj’ın yapım işinin proje ve teknik
          uygulamalarını yürüttü. Atatürk Barajındaki tüm mühendislik
          çalışmalarını “ATATÜRK BARAJINDA JEOTEKNİK UYGULAMALAR“ adlı kitabında
          yayınladı.1994 yılında kurulan Yerson Limited Şirketi çok sayıda resmi
          ve özel sektöre ait işleri üstlendi ve tamamını başarı ile zamanından
          önce teslim etti.
        </p>
      </div>
    </div>
  );
}

export default WhoAreWe;
