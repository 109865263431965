import React from "react";

function Footer(props) {
  return (
    <div id="footer">
      <img src="./visuals/logo/b.png" />
    </div>
  );
}

export default Footer;
